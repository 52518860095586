import type { FC } from 'react';

import { InfoWrapper } from 'styles/notifications';

import Info from '../Info';

interface Props {
  errors: FieldErrors;
  isFullSize?: boolean;
  tag?: 'pre';
  isOverflow?: boolean;
  marginTop?: number;
  dataTestId?: string;
}

interface RenderErrorsProps {
  errors: FieldErrors;
  tag?: 'pre';
  isOverflow?: boolean;
  dataTestId?: string;
}

const renderErrors = ({ errors, tag, isOverflow, dataTestId }: RenderErrorsProps) => {
  const renderedErrors = [];

  for (const key in errors) {
    renderedErrors.push(
      <Info
        key={key}
        isOverflow={isOverflow}
        tag={tag}
        message={errors[key].message}
        dataTestId={dataTestId}
      />,
    );
  }

  return renderedErrors;
};

const FieldsErrors: FC<Props> = ({ errors, isFullSize, tag, isOverflow, marginTop, dataTestId }) =>
  errors ? (
    <InfoWrapper $isFullSize={isFullSize} marginTop={marginTop}>
      {renderErrors({ errors, tag, isOverflow, dataTestId })}
    </InfoWrapper>
  ) : null;

export default FieldsErrors;
