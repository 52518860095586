import styled from 'styled-components';

const Container = styled.div<{ error: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  border: 1px solid ${(p) => (p.error ? p.theme.color.danger[2] : p.theme.color.gray[3])};
  border-radius: 2px;
  padding: 0 16px;

  &:hover {
    border: 1px solid ${(p) => (p.error ? p.theme.color.danger[2] : p.theme.color.gray[5])};
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: ${(p) => p.theme.color.blue[0]};

    &: hover {
      border: 1px solid ${(p) => p.theme.color.gray[3]};
    }
  }
`;

const Text = styled.span<{ filled: boolean }>`
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(p) => (p.filled ? p.theme.color.gray[10] : p.theme.color.gray[7])};
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  border-radius: 2px;
  padding: 10px 20px;
  transition: all 0.25s ease-out;
  height: 30px;
  min-width: max-content;
  background: none;
  font-size: 14px;
  line-height: 22px;
  color: ${(p) => p.theme.btn.outlined.default};
  padding: 5px 15px;
  border: ${(p) => '1px solid ' + p.theme.btn.outlined.default};

  &:hover {
    color: ${(p) => p.theme.color.gray[5]};
    border: ${(p) => '1px solid ' + p.theme.color.gray[5]};
  }

  &:disabled {
    color: ${(p) => p.theme.color.gray[5]};
    border: ${(p) => '1px solid ' + p.theme.color.gray[5]};
  }
`;

const Label = styled.label<{ show: boolean; error: boolean }>`
  position: absolute;
  top: ${(p) => (p.show ? '-5px' : '8px')};
  left: 16px;
  padding: 0 6px;
  color: ${(p) => (p.error ? p.theme.color.danger[2] : p.theme.color.gray[8])};
  background: ${(p) => (p.show ? p.theme.color.gray[0] : 'none')};
  opacity: ${(p) => (p.show ? '1' : '0')};
  transition: all linear 0.05s;
  font-size: 11px;
  line-height: 100%;
`;

export { Container, Button, Label, Text };
