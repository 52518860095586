import { DividerWrapper, Line, TextWrapper } from 'styles/divider';
import { InlineText } from 'styles/typography';

const Divider = ({ middleText }: { middleText?: string }) => {
  return (
    <DividerWrapper>
      <Line />
      {!!middleText && (
        <TextWrapper>
          <InlineText>{middleText}</InlineText>
        </TextWrapper>
      )}
      <Line />
    </DividerWrapper>
  );
};

export default Divider;
