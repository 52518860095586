import styled from 'styled-components';

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 40px;
`;

const StyledSearch = styled.input<{ filled: boolean; withAdditionalBtns?: boolean }>`
  width: 100%;
  height: 100%;
  border: 1px solid ${(p) => p.theme.color.gray[3]};
  border-radius: 2px;
  background: none;
  font-size: ${(p) => p.theme.typo.p.size.md};
  line-height: ${(p) => p.theme.typo.p.lineHeight.md};
  color: ${(p) => p.theme.color.gray[10]};
  padding: 0 16px 0 45px;
  -moz-appearance: textfield;

  ${({ filled }) => filled && `padding-right: 45px;`}
  ${({ withAdditionalBtns }) => withAdditionalBtns && `padding-right: 115px;`}

  &::placeholder {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  &:hover {
    border: 1px solid ${(p) => p.theme.color.gray[5]};
  }

  &:focus {
    outline: none;
    border: 1px solid ${(p) => p.theme.color.blue[5]};
    box-shadow: 0px 0px 5px rgba(72, 95, 155, 0.25);
  }

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const SearchIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
  cursor: pointer;
`;

const SearchLeftIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  position: absolute;
  top: 0;
  left: 15px;
  height: 100%;
`;

export { SearchContainer, StyledSearch, SearchIconWrapper, SearchLeftIconWrapper };
