import Image from 'next/legacy/image';
import type { FC } from 'react';

import { InfoContainer, InlineTextWrapper } from 'styles/notifications';
import { InlineText } from 'styles/typography';

interface Props {
  type?: NotificationType;
  message: string;
  children?: string | JSX.Element;
  withoutIcon?: boolean;
  leftText?: string;
  tag?: 'pre';
  isOverflow?: boolean;
  dataTestId?: string;
}

const FieldErrors: FC<Props> = ({
  type = 'error',
  message,
  children,
  withoutIcon,
  leftText,
  tag,
  isOverflow,
  dataTestId,
}) => {
  const iconSrc = `/icons/notifications/info/${type}.svg`;

  return (
    <InfoContainer
      data-testid={dataTestId || message}
      className={`${type} ${withoutIcon && 'withoutIcon'}`}
      role="note"
    >
      {!withoutIcon && (
        <Image src={iconSrc} width={20} height={20} alt={`information type ${type}`} />
      )}
      <InlineTextWrapper $isOverflow={isOverflow}>
        {leftText && <InlineText className="bold">{leftText}</InlineText>}
        <InlineText as={tag ?? 'span'}>{message}</InlineText>
      </InlineTextWrapper>
      {children}
    </InfoContainer>
  );
};

export default FieldErrors;
