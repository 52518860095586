import styled from 'styled-components';

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  color: ${(p) => p.theme.color.gray[7]};
`;

const Line = styled.span`
  flex: 1;
  border-bottom: ${(p) => `1px solid ${p.theme.color.gray[6]}`};
`;

const TextWrapper = styled.div`
  padding: 0 8px;
`;

export { DividerWrapper, Line, TextWrapper };
