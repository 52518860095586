import styled from 'styled-components';

const TextEditorLabel = styled.label<{ show: boolean; error: boolean; labelLeft: boolean }>`
  color: ${(p) => (p.error ? p.theme.color.danger[2] : p.theme.color.gray[8])};
  display: ${(p) => (p.show ? null : 'none')};
  transition: all linear 0.2s;
  font-size: 11px;
  line-height: 100%;
  margin-bottom: -20px;
  margin-left: 22px;
  ${(p) => p.labelLeft && 'margin-right: auto'}
`;

export { TextEditorLabel };
